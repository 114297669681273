import InfoTooltip from '@/components/InfoTooltip';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import Button from '@/components/Button';

import './ButtonSwitchInput.css';

type ButtonSwitchInputProps = {
  className?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  tooltip?: ReactNode;
  values: {
    label: string;
    value: string;
    hover?: ReactNode;
  }[];
  showError?: boolean;
};

const ButtonSwitchInput: FC<ButtonSwitchInputProps> = ({
  className,

  formik,
  name,
  label,
  tooltip,
  values,
  showError = true,
}) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="block font-bold text-sm mb-2 flex">
          {label} {tooltip && <InfoTooltip text={tooltip} className="ml-2" />}
        </label>
      )}
      <div className={clsx(className, 'ButtonSwitchInput-wrapper flex')}>
        {values.map((val) => (
          <Button
            key={val.value}
            className={clsx(
              'flex-1 border-primary-300',
              formik.values[name] === val.value && 'ButtonSwitchInput-active',
              val.hover && 'has-tooltip'
            )}
            onClick={(event) => {
              event.preventDefault();
              formik.setFieldValue(name, val.value);
            }}
          >
            {val.label}
            {val.hover && (
              <div className="tooltip rounded overflow-hidden shadow-lg bottom-12 left-0 right-0 whitespace-nowrap cursor-default">
                {val.hover}
              </div>
            )}
          </Button>
        ))}
      </div>
      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </>
  );
};

export default ButtonSwitchInput;
