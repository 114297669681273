import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, ReactNode } from 'react';

import InfoTooltip from '@/components/InfoTooltip';

import './TextInput.css';

export type TextInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  tooltip?: ReactNode;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;
  autoFocus?: boolean;

  endPiece?: ReactNode;
};

const TextInput: FC<TextInputProps> = ({
  className,
  innerClassName,

  formik,
  name,
  label,
  tooltip,
  type = 'text',
  placeholder,
  disabled,
  showError = true,
  autoFocus,

  endPiece,
}) => {
  const Input = type === 'textarea' ? 'textarea' : 'input';
  return (
    <div
      className={clsx(className, 'TextInput-wrapper', disabled && 'opacity-50')}
    >
      {label && (
        <label htmlFor={name} className="block font-bold text-sm mb-2 flex">
          {label} {tooltip && <InfoTooltip text={tooltip} className="ml-2" />}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `TextInput-input flex w-full mt-1 text-sm items-center           
          rounded-md border-2 border-primary-100
          shadow-sm 
          bg-white`,
          disabled ? '' : 'hover:bg-gray-100'
        )}
      >
        <Input
          id={name}
          type={type}
          {...formik.getFieldProps(name)}
          className="block w-full p-2"
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {endPiece && (
          <div className="text-black opacity-60 mx-4 whitespace-nowrap">
            {endPiece}
          </div>
        )}
      </div>

      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default TextInput;
