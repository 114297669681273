import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

type InfoTooltipProps = {
  className?: string;
  text: ReactNode;
};

const InfoTooltip: FC<InfoTooltipProps> = ({ text, className }) => {
  const ref = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  useClickAway(ref, () => {
    setShowTooltip(false);
  });

  return (
    <div ref={ref} className={clsx(className, 'has-tooltip font-medium')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 text-primary"
        viewBox="0 0 20 20"
        fill="currentColor"
        onClick={() => setShowTooltip(true)}
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        />
      </svg>
      <div
        className={clsx(
          'tooltip left-0 bottom-full mb-2 rounded shadow-lg bg-gray-100 p-2',
          showTooltip && 'active'
        )}
      >
        {text}
      </div>
    </div>
  );
};

export default InfoTooltip;
