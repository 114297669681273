import Button from '@/components/Button';
import ButtonSwitchInput from '@/components/form/ButtonSwitchInput';
import TextInput from '@/components/form/TextInput';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { getPlantHardinessZone } from '@/lib/connectors/hardiness';
import { getViableTrees } from '@/lib/connectors/trees';
import { TerrainData, useTerrainData } from '@/lib/data';
import { useFormik } from 'formik';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useMemo } from 'react';
import * as Yup from 'yup';

const Home: FC<PageProps> = () => {
  const terrainData = useTerrainData();

  const formik = useFormik({
    initialValues: {
      sun: terrainData.sun ?? ('' as TerrainData['sun']),
      minTemperature: terrainData.minTemperature ?? ('' as unknown as number),
      soilHumidity:
        terrainData.soilHumidity ?? ('' as TerrainData['soilHumidity']),
    },
    validationSchema: Yup.object({
      sun: Yup.string().required(),
      minTemperature: Yup.number().required(),
      soilHumidity: Yup.string().required(),
    }),
    onSubmit: async ({ sun, minTemperature, soilHumidity }) => {
      terrainData.setTerrainData({ sun, minTemperature, soilHumidity });
      await navigate('/tree-guilds/');
    },
  });

  const numberOfViableTrees = useMemo(() => {
    const { minTemperature, soilHumidity, sun } = formik.values as any;

    return minTemperature !== '' && soilHumidity !== '' && sun !== ''
      ? getViableTrees({
          hardiness: getPlantHardinessZone(minTemperature),
          sun,
          soilHumidity,
        }).length
      : null;
  }, [formik.values]);

  return (
    <Layout className="relative">
      <SEO url="" title="Freeden" description="Tree guild builder" />

      <div className="container m-auto">
        <h1 className="text-3xl text-center font-bold w-full my-10">Freeden</h1>
        <form
          className="shadow-lg rounded-md max-w-xl m-auto p-8 bg-white"
          onSubmit={formik.handleSubmit}
        >
          <h2 className="text-lg mb-10">
            Before we begin, tell us a little more{' '}
            <strong>about the terrain</strong> you wish to plant the tree
            guilds.
          </h2>

          <ButtonSwitchInput
            formik={formik}
            name="sun"
            label="How sunny is your terrain?"
            tooltip={
              <div className="w-96">
                <p>
                  <strong>Full sun</strong> means that particular terrain patch
                  receives at least six hours of direct sunlight per day
                </p>

                <p className="mt-2">
                  <strong>Partial shade</strong> means that the plant needs 3-6
                  hours of direct sun per day.
                  <br />
                  *“Partial shade” also generally implies that the plant should
                  be protected from the sun during the afternoon when it is most
                  scorching.
                </p>

                <p className="mt-2">
                  <strong>Shade means</strong> your terrain patch receives less
                  than an hour of direct sunlight each day. It could also mean
                  dappled light through a tree canopy for most of the day.
                  <br />
                  *Full shade is not just a result of trees but also buildings,
                  fences and other structures that cast shadows or block the sun
                  rays.
                </p>
              </div>
            }
            values={[
              {
                value: '+',
                label: '🌕 Full Sun',
                hover: <img src="/full_sun.jpg" alt="full sun" />,
              },
              {
                value: '/',
                label: '🌓 Partial Shade',
                hover: <img src="/partial_shade.jpg" alt="partial shade" />,
              },
              {
                value: '-',
                label: '🌑 Shade',
                hover: <img src="/shade.jpg" alt="shade" />,
              },
            ]}
          />
          <div className="mb-8" />
          <TextInput
            formik={formik}
            name="minTemperature"
            label="What's the annual minimal temperature (°C)?"
            type="number"
            placeholder="eg: -10.4"
            tooltip={
              <div className="w-72">
                <p>
                  By knowing the absolute minimum annual temperature registered
                  in your area within the last 3 years we can assign you the
                  true and updated current hardiness zone you live in. <br />
                  *A hardiness zone is a geographic area defined to encompass a
                  certain range of climatic conditions relevant to plant growth
                  and survival.
                </p>
              </div>
            }
          />
          <div className="mb-8" />
          <ButtonSwitchInput
            formik={formik}
            name="soilHumidity"
            label="Is the terrain well drained or puddling?"
            tooltip={
              <div className="w-96">
                <p>
                  Because some soils drain poorly and cause plant roots to sit
                  too long in wet conditions and rot, picking the right kind of
                  plant for your current conditions is paramount for plant
                  survival. If you have heavy clay or compacted soil, you should
                  either amend the soil to make it more porous or choose plants
                  that can tolerate wet areas.
                </p>

                <p className="mt-2">
                  <strong>Well drained</strong> soil is that which allows water
                  to percolate through it reasonably quickly and not pool.
                  <br />
                  *Most trees grow best in a deep, moist, well-drained soil.
                  Each species has a different level of tolerance to soils on
                  either the wet or dry side of the ideal.
                </p>

                <p className="mt-2">
                  <strong>Puddling</strong> means wet soil that is temporarily
                  water-logged due to rain, flood or irrigation. <br />
                  *Wet soils are a problem for plants as it deprives the roots
                  from oxygen causing them to die.
                </p>
              </div>
            }
            values={[
              {
                value: 'D',
                label: '🌵 Well Drained',
                hover: <img src="/well_drained.jpg" alt="well drained" />,
              },
              {
                value: 'P',
                label: '💧 Puddling',
                hover: <img src="/puddling.jpg" alt="puddling" />,
              },
            ]}
          />
          {numberOfViableTrees !== null && (
            <div className="text-sm text-gray-400 mt-16">
              We found <strong>{numberOfViableTrees || 'no'}</strong> trees that
              you could plan in these conditions.
            </div>
          )}
          <div className="mb-16" />
          <div className="w-full flex justify-end">
            <div>
              <Button
                type="submit"
                color="secondary"
                className="flex items-center"
              >
                Create Tree guild
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 ml-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Home;
